import clsx from 'clsx';
import { isFunction } from 'lodash-es';
import { CheckboxProps, Checkbox } from 'react-aria-components';

function _Checkbox({ children, ...props }: CheckboxProps) {
  return (
    <Checkbox {...props}>
      {(bag) => (
        <div className="flex items-center gap-x-4">
          <div className="flex h-4 w-4 items-center justify-center p-0.5">
            <div
              className={clsx(
                'flex h-4 w-4 items-center justify-center rounded border p-0.5 transition-all duration-300',
                bag.isSelected ? 'bg-grey-900 border-grey-900' : 'border-grey-500 bg-transparent',
              )}>
              <svg
                aria-hidden="true"
                className="flex items-center justify-center transition-all"
                fill="none"
                height={12}
                stroke="#FFF"
                strokeDasharray={22}
                strokeDashoffset={bag.isSelected ? 44 : 66}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                viewBox="0 0 18 18"
                width={12}>
                <polyline points="2 9 7 14 16 4" />
              </svg>
            </div>
          </div>
          {children && (
            <div className="text-grey-700">{isFunction(children) ? children(bag) : children}</div>
          )}
        </div>
      )}
    </Checkbox>
  );
}

export { _Checkbox as Checkbox };
